<template>
  <div>
    <mainSection />
    <section class="goals overflow-hidden">
      <div class="goals-container-title first">
        <h4 class="title mb-0 mr-5">{{ t("goals").ourgoals }}</h4>
        <span class="title-border"></span>
      </div>
      <img
        class="goals-content-musk"
        src="/images/website/goals-musk-left.png"
        width="140"
      />
      <div class="goals-content py-5">
        <b-col>
          <b-row class="py-5">
            <b-col
              cols="12"
              lg="4"
              v-for="(goal, index) in goalsWebsite"
              :key="index"
              class="goals-content-images-container"
            >
              <div
                class="goals-card"
                data-aos="fade-up"
                data-aos-duration="5000"
              >
                <h4 class="goals-card-title">{{ index + 1 }}</h4>
                <div class="goals-card-image-desc-container">
                  <img
                    class="goals-card-image"
                    :src="
                      goal.imageDto.path
                        ? goal.imageDto.path.replace(
                            '~',
                            $store.getters['app/domainHost']
                          )
                        : ''
                    "
                    style="object-fit: cover"
                  />
                  <p class="lead goals-card-desc">{{ goal.description }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </div>
      <div class="goals-container-title last">
        <span class="title-border"></span>
        <span>G</span>
        <span>N</span>
        <span>I</span>
        <span>N</span>
        <span>N</span>
        <span>I</span>
        <span>P</span>
        <span>S</span>
      </div>
    </section>
    <section class="our-values">
      <div class="section-title-contaner">
        <h2 class="section-title left">{{ t("values").ourvalues }}</h2>
      </div>
      <div class="our-values-card-container py-5">
        <b-row>
          <b-col
            cols="12"
            lg="4"
            v-for="(value, index) in valuesWebsite"
            :key="index"
            class="our-values-card-parent"
          >
            <div
              class="our-values-card"
              :data-aos="
                'fade-' +
                  (index == 0
                    ? 'left'
                    : index == 1
                    ? 'up'
                    : index == 2
                    ? 'right'
                    : '')
              "
              data-aos-duration="5000"
            >
              <div class="our-values-card-content">
                <img
                  class="our-values-card-image"
                  :src="
                    value.imageDto.path
                      ? value.imageDto.path.replace(
                          '~',
                          $store.getters['app/domainHost']
                        )
                      : ''
                  "
                  height="332"
                  width="304"
                  style="object-fit: cover"
                />
                <p class="our-values-card-text">{{ value.description }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
    <section class="managers">
      <div class="section-title-contaner">
        <h2 class="section-title right">{{ t("managers").managers }}</h2>
      </div>
      <div class="managers-cards-container py-5">
        <b-col>
          <b-row>
            <b-col
              cols="12"
              lg="4"
              class="managers-card-parent"
              v-for="(employee, index) in websiteEmployeeList"
              :key="index"
            >
              <div
                class="managers-card"
                data-aos="fade-up"
                data-aos-duration="5000"
              >
                <div class="managers-card-image-desc-container">
                  <img
                    class="managers-card-image"
                    :src="
                      employee.imageDto.path
                        ? employee.imageDto.path.replace(
                            '~',
                            $store.getters['app/domainHost']
                          )
                        : '/images/website/employee.svg'
                    "
                  />
                  <div class="managers-card-desc">
                    <h4>
                      {{ employee.firstName + " " + employee.lastName }}
                    </h4>
                    <span> {{ employee.position }} </span>
                  </div>
                  <span class="prand">ALFAISAL</span>
                  <!-- <div class="name">
                    <span v-for="(letter, index) in 'MOSHAMAD'" :key="index">{{
                      letter.toUpperCase()
                    }}</span>
                  </div> -->
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </div>
    </section>
  </div>
</template>
<script>
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import mainSection from "@/views/website/pages/about/components/main-section.vue";
  import { handleLangChange } from "@/libs/event-bus";
  import { mapGetters, mapActions } from "vuex";
  export default {
    components: {
      mainSection,
    },
    computed: {
      ...mapGetters(["aboutusWebsite", "valuesWebsite", "goalsWebsite", "websiteEmployeeList"]),
    },
    mounted() {
      this.init();
      handleLangChange(this.init);
      addEventListener("resize", this.setGoalSectionAngle);
    },
    metaInfo() {
      return {
        titleTemplate: "Al Faisal - " + this.$t("title.aboutus"),
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: [
          {
            name: "description",
            content: this.aboutusWebsite.description,
          },
          {
            name: "keywords",
            content: "keywords, keywords, keywords, ...",
          },
        ],
      };
    },
    methods: {
      init() {
        this.getAboutPageForWebsite();
        this.setGoalSectionAngle();
      },
      ...mapActions(["getAboutPageForWebsite"]),
      setGoalSectionAngle() {
        let servicesContainerTitleFirst = document.querySelector(
          ".goals-container-title.first"
        );
        let servicesContainerTitleLast = document.querySelector(
          ".goals-container-title.last"
        );
        let goalsContainerTitleTitle = document.querySelector(
          ".goals-container-title .title"
        );
        let angle = (Math.atan(100 / innerWidth) * 180) / Math.PI;

        if (document.querySelector("html").dir == "rtl") {
          servicesContainerTitleFirst.style.transform =
            "rotate(" + -angle + "deg)";
          servicesContainerTitleLast.style.transform =
            "rotate(" + angle + "deg)";
          goalsContainerTitleTitle.style.transform = "rotate(" + angle + "deg)";
        } else {
          servicesContainerTitleFirst.style.transform =
            "rotate(" + angle + "deg)";
          servicesContainerTitleLast.style.transform =
            "rotate(" + -angle + "deg)";
          goalsContainerTitleTitle.style.transform =
            "rotate(" + -angle + "deg)";
        }
      },
    },
    setup() {
      const { t } = useI18nUtils();
      return { t };
    },
    beforeDestroy() {
      this.$store.dispatch("app/setLoadingWebsite", true);
    },
  };
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .goals {
    position: relative;
    .goals-container-title {
      display: flex;
      align-items: center;
      color: $primary;
      font-size: 12px;
      transform-origin: 0 center;
      .title {
        z-index: 1;
        background: $primary;
        padding: 12px 100px;
        color: #fff;
        &:after {
          content: "";
          background: #621200;
          position: absolute;
          left: 0;
          bottom: -12px;
          width: 17px;
          height: 12px;
          clip-path: polygon(0 0, 100% 0, 0 100%);
        }
      }
      .title-border {
        flex-grow: 1;
        background: $primary;
        height: 3px;
      }
      span {
        padding: 0 14px;
      }
    }
    .goals-container-title.first {
      position: relative;
      top: 12px;
      z-index: 1;
    }
    .goals-container-title.last {
      .title-border {
        margin-left: 24px;
      }
    }
    .goals-content {
      min-height: 300px;
      z-index: 0;
      display: flex;
      position: relative;
      background: #efefef;
      margin-left: 24px;
      clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 calc(100% - 100px));
      .goals-content-images-container {
        margin-bottom: 2rem;
        @media screen and (min-width: 992px) {
          &:nth-of-type(2) {
            margin-top: 80px;
          }
        }
        .goals-card {
          padding: 10px 0;
          width: 300px;
          margin: auto;
          position: relative;
          .goals-card-title {
            transition: all 0.4s linear;
            margin: 0;
            position: absolute;
            font-size: 40px;
            font-weight: 600;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            color: #fff;
          }
          .goals-card-image-desc-container {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              z-index: 1;
            }
            .goals-card-image {
              border-radius: 8px;
              height: 360px;
              width: 100%;
              z-index: 0;
            }
            .goals-card-desc {
              pointer-events: none;
              transition: all 0.4s linear;
              position: absolute;
              left: -100%;
              bottom: 40px;
              z-index: -1;
              width: calc(100% + 14px);
              color: #fff;
              background: $secondaryPrimary;
              padding: 30px 1rem;
              padding-right: calc(1rem + 15px);
              text-align: center;
              line-height: 2;
              opacity: 0;
              visibility: hidden;
              &:after {
                content: "";
                position: absolute;
                bottom: -15px;
                width: 15px;
                height: 15px;
                right: 0;
                background: #b68532;
                clip-path: polygon(0 0, 100% 0, 100% 100%);
              }
            }
          }
          &:hover {
            .goals-card-title {
              top: 20%;
            }
            .goals-card-image-desc-container {
              .goals-card-desc {
                z-index: 2;
                left: 0;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
    .goals-content-musk {
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 40px;
    }
  }
  .our-values {
    overflow-x: hidden;
    .our-values-card-container {
      .our-values-card-parent {
        .our-values-card {
          position: relative;
          width: 360px;
          margin: auto;
          margin-bottom: 3rem;
          .our-values-card-content {
            overflow: hidden;
            border-radius: 4px;
            .our-values-card-image {
              width: 100%;
              border-radius: 4px;
              position: relative;
            }
            .our-values-card-text {
              z-index: 1;
              position: absolute;
              top: 0;
              color: #fff;
              margin: 0;
              padding: 16px;
              text-align: center;
            }
            &:after {
              content: "";
              position: absolute;
              background: $primary;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              border-radius: 4px;
            }
          }
          &:after {
            content: "";
            position: absolute;
            background: $primary;
          }
        }
        @media screen and (min-width: 992px) {
          .our-values-card {
            .our-values-card-text {
              width: 280px;
            }
          }
          &:first-of-type {
            .our-values-card {
              &:after {
                height: 100px;
                width: 4px;
                left: -4px;
                top: 50%;
                transform: translateY(-50%);
              }
              .our-values-card-content {
                &:after,
                .our-values-card-image {
                  transform: skewX(-11deg) translateX(-56px);
                }
                &:after {
                  clip-path: polygon(0 0, 93% 0, 76% 100%, 0 100%);
                }
                .our-values-card-text {
                  left: 0;
                }
              }
            }
          }
          &:nth-of-type(2) {
            .our-values-card {
              &:after {
                width: 100px;
                height: 4px;
                bottom: -23px;
                left: 50%;
                transform: translateX(-50%);
              }
              .our-values-card-content {
                perspective: 300px;
                transform-style: preserve-3d;
                overflow: visible;
                &:after,
                .our-values-card-image {
                  transform: rotate3d(1, 0, 0, -16deg);
                  margin-top: -20px;
                }
                &:after {
                  height: calc(100% + 20px);
                }
              }
              .our-values-card-text {
                position: absolute;
                top: 8px;
                left: 50%;
                padding: 15px;
                transform: translateX(-50%);
                color: #fff;
              }
            }
          }
          &:last-of-type {
            .our-values-card {
              &:after {
                height: 100px;
                width: 4px;
                right: -4px;
                top: 50%;
                transform: translateY(-50%);
              }
              .our-values-card-content {
                &:after,
                .our-values-card-image {
                  transform: skewX(11deg) translateX(56px);
                }
                &:after {
                  clip-path: polygon(24px 0, 100% 0, 100% 100%, 88px 100%);
                }
                .our-values-card-text {
                  left: 80px;
                }
              }
            }
          }
        }
      }
    }
  }
  .managers {
    .managers-cards-container {
      position: relative;
      .managers-card-parent {
        margin-bottom: 3rem;
        .managers-card {
          padding: 10px 0;
          width: 260px;
          margin: auto;
          position: relative;
          &:after,
          &:before {
            content: "";
            position: absolute;
            left: 0;
            height: 3px;
            width: 100%;
            background: $secondaryPrimary;
          }
          &:after {
            top: 0;
          }
          &:before {
            bottom: 0;
          }
          .managers-card-image-desc-container {
            position: relative;
            .managers-card-image {
              height: 280px;
              width: 100%;
              z-index: 0;
              object-fit: cover;
            }
            .managers-card-desc {
              pointer-events: none;
              transition: all 0.4s linear;
              position: absolute;
              left: -100%;
              bottom: 40px;
              z-index: -1;
              width: calc(100% + 14px);
              color: #fff;
              background: $secondaryPrimary;
              padding: 16px 1rem;
              padding-right: calc(1rem + 15px);
              text-align: center;
              line-height: 2;
              opacity: 0;
              visibility: hidden;
              &:after {
                content: "";
                position: absolute;
                bottom: -15px;
                width: 15px;
                height: 15px;
                right: 0;
                background: #b68532;
                clip-path: polygon(0 0, 100% 0, 100% 100%);
              }
            }
            .prand {
              position: absolute;
              bottom: -30px;
              left: 0;
              letter-spacing: 8px;
              font-size: 12px;
            }
            .name {
              position: absolute;
              bottom: 0;
              height: 100%;
              left: -16px;
              font-size: 12px;
              color: $secondaryPrimary;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
            }
          }
          &:hover {
            .managers-card-image-desc-container {
              .managers-card-desc {
                left: 0;
                z-index: 2;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  .agent {
    .sub-title-bottom {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      display: flex;
      width: 100%;
      padding: 0 16px;
      justify-content: space-between;
      color: $primary;
      span {
        padding: 10px;
      }
    }
    .agent-logo-container {
      height: 500px;
      width: calc(100% + 80px);
      position: relative;
      left: -80px;
      clip-path: polygon(0 0, 100% 0, calc(100% - 80px) 100%, 0 100%);
      .left-border-style {
        width: 40px;
        height: 100%;
        border-top: solid 96px $secondaryPrimary;
        border-bottom: solid 96px $secondaryPrimary;
        position: relative;
      }
      .agent-content-title {
        display: flex;
        h4 {
          letter-spacing: 24px;
          padding: 4px 24px 4px 0;
          color: $primary;
          margin: 0;
        }
        .title-border {
          flex-grow: 1;
          background: $secondaryPrimary;
        }
      }
      .agent-content-image {
        border-top: solid 4px $primary;
        border-bottom: solid 4px $primary;
        display: flex;
        flex-direction: column;
        .image {
          background: $secondaryPrimary;
          flex-grow: 1;
        }
      }
    }
  }
  [dir="ltr"] {
    .goals {
      .goals-container-title {
        .title {
          &:after {
            clip-path: polygon(0 0, 100% 0, 100% 100%);
          }
        }
      }
      .goals-content {
        clip-path: polygon(0 0, 100% 100px, 100% calc(100% - 100px), 0 100%);
        .goals-content-images-container {
          .goals-card-image-desc-container {
            .goals-card-desc {
              &:after {
                clip-path: polygon(0 0, 100% 0, 0 100%);
              }
            }
          }
        }
      }
      .goals-content-musk {
        transform: scaleX(-1);
      }
    }
    .our-values {
      .our-values-card-container {
        .our-values-card-parent {
          @media screen and (min-width: 992px) {
            &:first-of-type {
              .our-values-card {
                .our-values-card-content {
                  &:after {
                    clip-path: polygon(24px 0, 100% 0, 100% 100%, 88px 100%);
                  }
                }
              }
            }
            &:nth-of-type(2) {
              .our-values-card {
                .our-values-card-content {
                  &:after,
                  .our-values-card-image {
                    transform: rotate3d(1, 0, 0, 16deg);
                  }
                }
              }
            }
            &:last-of-type {
              .our-values-card {
                .our-values-card-content {
                  &:after {
                    clip-path: polygon(0 0, 93% 0, 76% 100%, 0 100%);
                  }
                }
              }
            }
          }
        }
      }
    }
    .managers {
      .managers-cards-container {
        .managers-card-parent {
          .managers-card {
            .managers-card-image-desc-container {
              .managers-card-desc {
                &:after {
                  left: 0;
                  right: auto;
                }
              }
              .name {
                left: auto;
                right: -16px;
              }
            }
            &:hover {
              .managers-card-image-desc-container {
                .managers-card-desc {
                  left: -14px;
                }
              }
            }
          }
        }
      }
    }
    .agent {
      .agent-logo-container {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 80px 100%);
        .agent-content-title {
          h4 {
            padding: 4px 0 4px 24px;
          }
          .title-border {
            flex-grow: 1;
            background: $secondaryPrimary;
          }
        }
      }
    }
  }
</style>
