<template>
  <section class="about-header py-5 mt-3">
    <b-container>
      <b-row>
        <b-col cols="12" lg="6" order="2">
          <div class="about-text-container" data-aos="fade-left" data-aos-duration="5000">
            <p class="lead about-text">{{ aboutusWebsite.description }}</p>
            <b-button variant="outline-primary" class="mt-2">{{
              t("global").more
            }}</b-button>
          </div>
        </b-col>
        <b-col cols="12" lg="6" order="1" order-lg="2">
            <div class="about-roller mb-3" data-aos="fade-up" data-aos-duration="5000">
              <div class="top-piace"></div>
              <div class="bottom-piace">ALFAISAL</div>
              <div class="roller-container">
                <img
                  class="roller-bg"
                  :src="
                    aboutusWebsite.imageDto.path
                      ? aboutusWebsite.imageDto.path.replace(
                          '~',
                          $store.getters['app/domainHost']
                        )
                      : ''
                  "
                />
              </div>
            
            </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import { mapGetters } from "vuex";
  export default {
    computed: {
      ...mapGetters(["aboutusWebsite"]),
    },
    setup() {
      const { t } = useI18nUtils();
      return { t };
    },
  };
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .about-text-container {
    padding: 0 3rem;
    .about-text {
      line-height: 2;
      font-weight: bold;
      position: relative;
      padding-bottom: 1.5rem;
      &:after {
        position: absolute;
        content: "";
        background: $secondaryPrimary;
        height: 4px;
        width: 200px;
        bottom: 0;
        left: 0;
      }
    }
  }
  .about-roller {
    transform: none;
                             
    min-height: 356px;
    margin: auto;
    position: relative;
    .top-piace {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 7px;
      background: #000;
      width: 100px;
      height: 26px;
      z-index: 0;
    }
    .bottom-piace {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 7px;
      border: solid 2px #000;
      padding: 2px 18px 2px 0;
      letter-spacing: 18px;
      color: $primary;
      z-index: 0;
    }
    .title {
      background: $primary;
      padding: 8px 50px;
      display: inline-block;
      border: solid 2px #fff;
      color: #fff;
      font-weight: bold;
      position: absolute;
      bottom: 60px;
      left: -108px;
      z-index: 2;
    }
    .roller-container {
    
      .roller-bg {
       object-fit: cover;
        transform-origin: top center;
        width: 100%;
        position: relative;
        z-index: 1;
        height: 328px;
        border-radius: 20px;
      }
    }
  }
  [dir="ltr"] {
    .about-roller {
      .bottom-piace {
        padding: 2px 0 2px 18px;
      }
      .roller-container {
        .roller-bg {
          transform: rotate3d(1, 0, 0, 35deg);
        }
      }
    }
  }
</style>
