var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('mainSection'),_c('section',{staticClass:"goals overflow-hidden"},[_c('div',{staticClass:"goals-container-title first"},[_c('h4',{staticClass:"title mb-0 mr-5"},[_vm._v(_vm._s(_vm.t("goals").ourgoals))]),_c('span',{staticClass:"title-border"})]),_c('img',{staticClass:"goals-content-musk",attrs:{"src":"/images/website/goals-musk-left.png","width":"140"}}),_c('div',{staticClass:"goals-content py-5"},[_c('b-col',[_c('b-row',{staticClass:"py-5"},_vm._l((_vm.goalsWebsite),function(goal,index){return _c('b-col',{key:index,staticClass:"goals-content-images-container",attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"goals-card",attrs:{"data-aos":"fade-up","data-aos-duration":"5000"}},[_c('h4',{staticClass:"goals-card-title"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"goals-card-image-desc-container"},[_c('img',{staticClass:"goals-card-image",staticStyle:{"object-fit":"cover"},attrs:{"src":goal.imageDto.path
                      ? goal.imageDto.path.replace(
                          '~',
                          _vm.$store.getters['app/domainHost']
                        )
                      : ''}}),_c('p',{staticClass:"lead goals-card-desc"},[_vm._v(_vm._s(goal.description))])])])])}),1)],1)],1),_vm._m(0)]),_c('section',{staticClass:"our-values"},[_c('div',{staticClass:"section-title-contaner"},[_c('h2',{staticClass:"section-title left"},[_vm._v(_vm._s(_vm.t("values").ourvalues))])]),_c('div',{staticClass:"our-values-card-container py-5"},[_c('b-row',_vm._l((_vm.valuesWebsite),function(value,index){return _c('b-col',{key:index,staticClass:"our-values-card-parent",attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"our-values-card",attrs:{"data-aos":'fade-' +
                (index == 0
                  ? 'left'
                  : index == 1
                  ? 'up'
                  : index == 2
                  ? 'right'
                  : ''),"data-aos-duration":"5000"}},[_c('div',{staticClass:"our-values-card-content"},[_c('img',{staticClass:"our-values-card-image",staticStyle:{"object-fit":"cover"},attrs:{"src":value.imageDto.path
                    ? value.imageDto.path.replace(
                        '~',
                        _vm.$store.getters['app/domainHost']
                      )
                    : '',"height":"332","width":"304"}}),_c('p',{staticClass:"our-values-card-text"},[_vm._v(_vm._s(value.description))])])])])}),1)],1)]),_c('section',{staticClass:"managers"},[_c('div',{staticClass:"section-title-contaner"},[_c('h2',{staticClass:"section-title right"},[_vm._v(_vm._s(_vm.t("managers").managers))])]),_c('div',{staticClass:"managers-cards-container py-5"},[_c('b-col',[_c('b-row',_vm._l((_vm.websiteEmployeeList),function(employee,index){return _c('b-col',{key:index,staticClass:"managers-card-parent",attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"managers-card",attrs:{"data-aos":"fade-up","data-aos-duration":"5000"}},[_c('div',{staticClass:"managers-card-image-desc-container"},[_c('img',{staticClass:"managers-card-image",attrs:{"src":employee.imageDto.path
                      ? employee.imageDto.path.replace(
                          '~',
                          _vm.$store.getters['app/domainHost']
                        )
                      : '/images/website/employee.svg'}}),_c('div',{staticClass:"managers-card-desc"},[_c('h4',[_vm._v(" "+_vm._s(employee.firstName + " " + employee.lastName)+" ")]),_c('span',[_vm._v(" "+_vm._s(employee.position)+" ")])]),_c('span',{staticClass:"prand"},[_vm._v("ALFAISAL")])])])])}),1)],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goals-container-title last"},[_c('span',{staticClass:"title-border"}),_c('span',[_vm._v("G")]),_c('span',[_vm._v("N")]),_c('span',[_vm._v("I")]),_c('span',[_vm._v("N")]),_c('span',[_vm._v("N")]),_c('span',[_vm._v("I")]),_c('span',[_vm._v("P")]),_c('span',[_vm._v("S")])])}]

export { render, staticRenderFns }